<template>
	<div class="container">
		<div class="main" v-show="!detail.show">
		  <div class="table-box">
		    <h4>心理知识共享</h4>
		  </div>
		  <div class="more">
		    <el-table
		      :data="mainList.list"
		      style="width: 100%"
		      border
		      :header-cell-style="tableHeaderColor"
			  :default-sort="{prop: 'date'}">
		      <el-table-column type="index" label="序号" width="50"></el-table-column>
		      <el-table-column prop="title" label="文章名称"></el-table-column>
		      <el-table-column prop="createTime" sortable label="发布时间" width="180"></el-table-column>
		      <el-table-column prop="readNum" sortable label="阅读量" width="120"></el-table-column>
		      <el-table-column label="操作" width="100" >
		        <template slot-scope="scope">
		          <el-button @click="go2Read(scope.row)" type="text" size="small">去阅读</el-button>
		        </template>
		      </el-table-column>
		    </el-table>
			<pagination
			  v-show="mainList.total > 0"
			  :total="mainList.total"
			  :page.sync="queryParams.pageNum"
			  :limit.sync="queryParams.pageSize"
			  @pagination="getMainList"
			/>
		  </div>
		</div>
		<div v-show="detail.show" class="detail-page">
			<h1 class="title">{{ detail.title }}</h1>
			<p class="time">发布时间：{{ detail.time }}</p>
			<div class="decor-line"></div>
			<div v-html="detail.content"></div>
			<div class="btn-wrap">
				<el-link size="medium" type="primary" plain :underline="false" @click="switchShow(true)">返回</el-link>
			</div>
		</div>
	</div>
</template>

<script>
import { getPsyList, psyAddReadNum } from "@/api/public/readingOrPsy"
export default {
	data() {
		return {
			queryParams: {
				pageSize: 15
			},
			mainList: {
				list: [],
				total: 0,
				loading: false
			},
			detail: {
				show: false,
				title: '',
				time: '',
				content: ''
			}
		}
	},
	created() {
		this.getMainList()
	},
	methods: {
		// 查询主列表
		getMainList() {
			getPsyList(this.queryParams).then(res => {
				this.mainList.list = res.data.list
				this.mainList.total = res.data.total
			})
		},
		// 表头样式
		tableHeaderColor() {
		    return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center; text-align: center;"
		},
		// 去阅读
		go2Read(row) {
			psyAddReadNum({
				id: row.id
			}).then(res => {
				this.detail.title = row.title
				this.detail.time = row.createTime
				this.detail.content = row.content
				this.switchShow(false)
			})
		},
		// 切换详情显示
		switchShow(refresh) {
			this.detail.show = !this.detail.show
			if (refresh) {
				this.getMainList()
			}
		}
	}
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
</style>
<style>
.detail-page {
	padding: 30px 22px 20px;
	text-indent: 30px;
	color: #616266;
	font-size: 16px;
	background-color: #FFF;
}
.detail-page .title {
	margin: 0;
	margin-bottom: 20px;
	font-size: 16px;
	text-align: center;
}
.detail-page .time {
	color: #B2B2B2;
	margin: 0;
	margin-bottom: 25px;
	text-align: center;
}
.detail-page .decor-line {
	border-bottom: 1px dashed #DDDADA;
	margin-bottom: 23px;
}
.btn-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}
</style>